/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onJoinClass = /* GraphQL */ `
  subscription OnJoinClass($classId: String) {
    onJoinClass(classId: $classId) {
      classId
      studentId
    }
  }
`;
export const onSubmitAnswer = /* GraphQL */ `
  subscription OnSubmitAnswer($classId: String) {
    onSubmitAnswer(classId: $classId) {
      classId
      studentId
      answer
    }
  }
`;
export const onGradeAnswer = /* GraphQL */ `
  subscription OnGradeAnswer($classId: String, $studentId: String) {
    onGradeAnswer(classId: $classId, studentId: $studentId) {
      classId
      studentId
      grade
    }
  }
`;
