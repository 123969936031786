import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


export default class CreateRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classRoomId: ""
    };

    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    this.state.classRoomId = text;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.history.push("/teacherroom/" + this.state.classRoomId);
  }

  render() {
    return (
      <Container fluid style={{ padding:24 }}>
        <Card>
          <Card.Header>
            <h3>교실명: {this.state.classRoomId}</h3>
          </Card.Header>
          <Card.Body>
            <Button variant="primary" type="submit" onClick={this.handleSubmit}>
              만들기
            </Button>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
