import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as subscriptions from "../graphql/subscriptions";
import * as mutations from "../graphql/mutations";

import '../App.css';
import gql from "graphql-tag";

export default class TeacherRoom extends Component {

    constructor(props) {
      super(props);

      const { classRoomId } = this.props.match.params;
      console.log(classRoomId)
      let students = {}
      this.state = {
        classRoomId,
        students : {}
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
      let joinClassSubscription = this.props.appsync_key_client
      .subscribe({
        query: gql(subscriptions.onJoinClass),
        fetchPolicy: "no-cache",
        variables: {
          classId: this.state.classRoomId
        }
      })
      .subscribe({
        next: async (event) => {
          console.log("got event")
          console.log(event)
          let studentId = event.data.onJoinClass.studentId

          if (this.state.students) {
            this.setState({
              students: {
                ...this.state.students,
                [studentId]: {},
              },
            });
          } else {
            this.setState({
              students: {
                [studentId]: {},
              },
            });
          }


        },
        error: (error) => {
          console.warn("ENVET" + error);
        }
      });

      let submitAnswerSubscription = this.props.appsync_key_client
      .subscribe({
        query: gql(subscriptions.onSubmitAnswer),
        fetchPolicy: "no-cache",
        variables: {
          classId: this.state.classRoomId
        }
      })
      .subscribe({
        next: async (event) => {
          console.log("got event")
          console.log(event)
          let studentId = event.data.onSubmitAnswer.studentId
          let answer = event.data.onSubmitAnswer.answer

          if (this.state.students) {
            this.setState({
              students: {
                ...this.state.students,
                [studentId]: {
                  answer: answer
                },
              },
            });
          } else {
            this.setState({
              students: {
                [studentId]: {
                  answer: answer
                },
              },
            });
          }


        },
        error: (error) => {
          console.warn("ENVET" + error);
        }
      });
    }

    async handleSubmit(event) {
      const target = event.target;
      const value = target.value;
      const studentId = target.name;

      event.preventDefault();

      let gradeAnswer = await this.props.appsync_key_client.mutate({
        mutation: gql(mutations.gradeAnswer),
        fetchPolicy: "no-cache",
        variables: {
          classId: this.state.classRoomId,
          studentId: studentId,
          grade: value

        }
      });

      console.log(gradeAnswer)

    }
    render() {

      let renderStudent = (studentId, student, index) => {
        console.log(studentId)
        console.log(student)
        return (
          // TODO: 들어온 학생 수대로 dynamically loading 하고 이름 매칭
          <Container style={{ padding:4 }}>
          <Card key={index} fluid>
              <Card.Header as="h5">{studentId}</Card.Header>
              <Card.Body as="h4">
                <Card.Text style={{ padding:6}} >
                  {student.answer}
                </Card.Text>

                <Container fluid>
                  <Row>
                    <Col>
                  <Button variant="outline-primary" block type="submit" name={studentId} value="correct" onClick={this.handleSubmit}>맞음</Button>
                    </Col>
                    <Col>
                  <Button variant="outline-primary" block type="submit" name={studentId} value="incorrect" onClick={this.handleSubmit}>틀림</Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
          </Card>
          </Container>
        )
      }

      return (
        <Container fluid style={{ padding:18 }}>
          <h3>교실명: {this.state.classRoomId}</h3>
          <div>
          {
            Object.entries(this.state.students).map((student, index) => {
                let studentId = student[0]
                let studentObject = student[1]

                return renderStudent(studentId, studentObject, index)
              })
          }
          </div>
        </Container>

      )
    }
}
