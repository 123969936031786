import './App.css';
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    let childProps = {
      history: this.props.history,
      appsync_key_client: this.props.appsync_key_client,
    }
    return (
      <div className="App">
        <Container fluid="true" className="rest-body">
          <Routes childProps={childProps} />
        </Container>
      </div>
    );
  }
}


export default withRouter(App);
