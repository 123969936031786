import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import reportWebVitals from './reportWebVitals';

const appsync_key_client = new AWSAppSyncClient({
  url: "https://qhztdx5ytnhnpfifdgrp3agymm.appsync-api.us-west-2.amazonaws.com/graphql",
  region: "us-west-2",
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: "da2-d4meb7t2mjeklgqglhde6gr2uy"
  },
  disableOffline: true
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <App 
      appsync_key_client={appsync_key_client}
    />
    </Router>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
