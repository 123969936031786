/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const joinClass = /* GraphQL */ `
  mutation JoinClass($classId: String, $studentId: String) {
    joinClass(classId: $classId, studentId: $studentId) {
      classId
      studentId
    }
  }
`;
export const submitAnswer = /* GraphQL */ `
  mutation SubmitAnswer($classId: String, $studentId: String, $answer: String) {
    submitAnswer(classId: $classId, studentId: $studentId, answer: $answer) {
      classId
      studentId
      answer
    }
  }
`;
export const gradeAnswer = /* GraphQL */ `
  mutation GradeAnswer($classId: String, $studentId: String, $grade: String) {
    gradeAnswer(classId: $classId, studentId: $studentId, grade: $grade) {
      classId
      studentId
      grade
    }
  }
`;
