import React from "react";
import { Switch } from "react-router-dom";
import StudentEnter from "./pages/StudentEnter";
import CreateRoom from "./pages/CreateRoom";
import Classroom from "./pages/Classroom";
import TeacherRoom from "./pages/TeacherRoom";
import PublicRoute from "./PublicRoute";

export default ({ childProps }) => (
  <Switch>
    <PublicRoute
      path="/teacher"
      exact
      component={CreateRoom}
      props={childProps}
    />
    
    <PublicRoute
      path="/student"
      component={StudentEnter}
      props={childProps} />

    <PublicRoute
      path="/classroom/:classRoomId/"
      component={Classroom}
      props={childProps}
    />

    <PublicRoute
      path="/teacherroom/:classRoomId/"
      component={TeacherRoom}
      props={childProps}
    />
  </Switch>
);
