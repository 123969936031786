import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as mutations from "../graphql/mutations";
import gql from "graphql-tag";

export default class StudentEnter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentName:"",
      classRoomId: ""};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    let joinClass = await this.props.appsync_key_client.mutate({
      mutation: gql(mutations.joinClass),
      fetchPolicy: "no-cache",
      variables: {
        classId: this.state.classRoomId.toUpperCase(),
        studentId: this.state.studentName
      }
    });

    console.log(joinClass)

    this.props.history.push({
      pathname: "/classroom/" + this.state.classRoomId.toUpperCase(),
      state: {
        studentId: this.state.studentName
      },
    });
  }

  render() {
    return (
      <Container fluid style={{ padding:24 }}>
        <Card>
          <Card.Header>
            <h3>학생 로그인</h3>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="studentname">
                <Form.Label><h4>학생 이름</h4></Form.Label>
                <Form.Control
                  name="studentName"
                  onChange={this.handleChange}
                  type="text"
                  size="lg"
                  placeholder="이름을 입력하세요." />
              </Form.Group>
                      <br/>
              <Form.Group controlId="classname">
                <Form.Label><h4>교실 코드</h4></Form.Label>
                <Form.Control
                  name="classRoomId"
                  onChange={this.handleChange}
                  type="text"
                  size="lg"
                  placeholder="교실 코드를 입력하세요." />
              </Form.Group>
              <Button variant="primary" type="submit" size="lg" onClick={this.handleSubmit}>
                교실 입장
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
