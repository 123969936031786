import React, { Component } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as subscriptions from "../graphql/subscriptions";
import * as mutations from "../graphql/mutations";

import '../App.css';
import gql from "graphql-tag";


export default class Classroom extends Component {

    constructor(props) {
      super(props);

      const { classRoomId } = this.props.match.params;
      console.log(classRoomId)
      const studentId = this.props.location.state.studentId;
      const grade = "없음";
      this.state = {
        classRoomId,
        studentId,
        grade
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleReset = this.handleReset.bind(this);

    }

    handleChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });

      console.log(this.state)
    }

    handleReset(event) {
      this.setState({
        grade: "없음"
      });
      console.log(this.state)
    }

    async handleSubmit(event) {
      event.preventDefault();

      let submitAnswer = await this.props.appsync_key_client.mutate({
        mutation: gql(mutations.submitAnswer),
        fetchPolicy: "no-cache",
        variables: {
          classId: this.state.classRoomId,
          studentId: this.state.studentId,
          answer: this.state.answer

        }
      });

      console.log(submitAnswer)
    }

    async componentDidMount() {
      let gradeAnswerSubscription = this.props.appsync_key_client
      .subscribe({
        query: gql(subscriptions.onGradeAnswer),
        fetchPolicy: "no-cache",
        variables: {
          classId: this.state.classRoomId,
          studentId: this.state.studentId
        }
      })
      .subscribe({
        next: async (event) => {
          console.log("got event")
          console.log(event)
          let receivedStudentId = event.data.onGradeAnswer.studentId
          let grade = event.data.onGradeAnswer.grade

          if (this.state.studentId == receivedStudentId) {
            if (grade == "correct")
            {
              this.setState({
                grade: "맞음"
              });
            }
            else
            {
              {
              this.setState({
                grade: "틀림"
              });
            }
            }
          }
        },
        error: (error) => {
          console.warn("ENVET" + error);
        }
      });
    }

    render() {
      return (
        <Container fluid style={{ padding:24 }}>
          <Card>
            <Card.Header><h4>교실명: {this.state.classRoomId}</h4>
            <h4>학생: {this.state.studentId}</h4></Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formAnswer">
                  <Form.Control
                    type="text" size="lg"
                    as="textarea" row={3}
                    onChange={this.handleChange}
                    placeholder="답을 입력하세요."
                    name="answer"
                    />
                </Form.Group>
                <Container fluid style={{ padding:4 }}>
                  <Row>
                    <Col>
                      <Button variant="primary" block type="submit"
                        onClick={this.handleSubmit}>
                        제출
                      </Button>
                    </Col>
                    <Col>
                      <Button variant="outline-primary" block type="reset"
                        onClick={this.handleReset}
                        >
                        지우기
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ padding:12 }}>
                    <Col>
                      <h4>채점결과: {this.state.grade}</h4>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      )
    }
}
